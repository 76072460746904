import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Container from '../common/Container';
import PricingCard from './../common/PricingCard';

const PricingGrid = ({ hidePrice }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allKurse {
          nodes {
            price
            title
            link
            frequenz
            desc
            id
            landingpage
            features {
              value
              field {
                label
              }
            }
            remoteKurseImage {
              url
            }
          }
        }
      }
    `
  );

  return (
    <Container>
      <div className="space-y-32">
        {data.allKurse.nodes.map((item, i) => {
          return (
            <div className="grid gap-8 md:grid-cols-2" key={item.id}>
              <div className={`${i % 2 === 0 ? 'md:order-2' : ''}`}>
                <PricingCard title={item.title} desc={item.desc} price={hidePrice ? null : item.price} priceFrequenz={item.frequenz} features={item.features} link={item.link} landingpage={item.landingpage} />
              </div>
              <div className={`text-center ${i % 2 === 0 ? 'md:order-1' : ''}`}>
                <div className="animate-shake-vertical">
                  <img src={item.remoteKurseImage.url} className="inline w-full max-w-md" alt={item.title} title={item.title} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default PricingGrid;
