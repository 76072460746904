import React from 'react';
import P from '../typography/P';

interface BlurbNumberProps {
  number: number;
  title?: string;
  text: string;
  bg?: string;
}

const BlurbNumber: React.FC<BlurbNumberProps> = ({ number, title, text, bg }) => {
  return (
    <div className="text-center">
      <div className={`mx-auto mb-3 flex h-16 w-16 items-center justify-center rounded-full font-display text-xl font-bold ${bg ? bg : 'bg-white text-brand-gray'}`}>{number}</div>
      {title ? (
        <P>
          <span className="mb-3 text-lg font-bold">{title}</span>
        </P>
      ) : null}
      <P>{text}</P>
    </div>
  );
};

export default BlurbNumber;
