import React from 'react';
import { graphql } from 'gatsby';
import Blurb from '~components/common/Blurb';
import Container from '~components/common/Container';
import GridFour from '~components/common/GridFour';
import GridThree from '~components/common/GridThree';
import PageHeader from '~components/sections/PageHeader';
import Layout from '~components/layouts/Default';
import Courses from '~components/sections/Courses';
import H2 from '~components/typography/H2';
import H3 from '~components/typography/H3';
import P from '~components/typography/P';
import Button from '~components/common/Button';
import GridTwo from '~components/common/GridTwo';
import Divider from '~components/sections/Divider';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BlurbNumber from '~components/common/BlurbNumber';

const svgs = {
  certificate: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-white">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="15" cy="15" r="3" />
      <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
      <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
      <line x1="6" y1="9" x2="18" y2="9" />
      <line x1="6" y1="12" x2="9" y2="12" />
      <line x1="6" y1="15" x2="8" y2="15" />
    </svg>
  ),
  money: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-white">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M17.2 7a6 7 0 1 0 0 10" />
      <path d="M13 10h-8m0 4h8" />
    </svg>
  ),
  mobile: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-white">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <rect x="13" y="8" width="8" height="12" rx="1" />
      <path d="M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9" />
      <line x1="16" y1="9" x2="18" y2="9" />
    </svg>
  ),
  date: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-white">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <rect x="4" y="5" width="16" height="16" rx="2" />
      <line x1="16" y1="3" x2="16" y2="7" />
      <line x1="8" y1="3" x2="8" y2="7" />
      <line x1="4" y1="11" x2="20" y2="11" />
      <rect x="8" y="15" width="2" height="2" />
    </svg>
  ),
  book: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto h-8 w-8 text-white">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />
      <line x1="13" y1="8" x2="15" y2="8" />
      <line x1="13" y1="12" x2="15" y2="12" />
    </svg>
  ),
  plan: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto h-8 w-8 text-white ">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M3 7h1.948c1.913 0 3.705 .933 4.802 2.5a5.861 5.861 0 0 0 4.802 2.5h6.448" />
      <path d="M3 17h1.95a5.854 5.854 0 0 0 4.798 -2.5a5.854 5.854 0 0 1 4.798 -2.5h5.454" />
      <path d="M18 15l3 -3l-3 -3" />
    </svg>
  ),
  knowledge: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-white">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6" />
      <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4" />
    </svg>
  ),
  feedback: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto h-8 w-8 text-white">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />
      <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
    </svg>
  ),
  community: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto h-8 w-8 text-white">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="5" r="2" />
      <circle cx="5" cy="19" r="2" />
      <circle cx="19" cy="19" r="2" />
      <circle cx="12" cy="14" r="3" />
      <line x1="12" y1="7" x2="12" y2="11" />
      <line x1="6.7" y1="17.8" x2="9.5" y2="15.8" />
      <line x1="17.3" y1="17.8" x2="14.5" y2="15.8" />
    </svg>
  ),
  extras: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto h-8 w-8 text-white">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
    </svg>
  )
};

function Kurse({ location, data }) {
  return (
    <Layout
      location={location}
      title="★ Kurse für Prog. Muskelentspannung, Autogenes Training"
      desc="Zertifizierte Kurse für die Progressive Muskelentspannung und Autogenes Training mit mindestens 75 Euro Krankenkassenzuschuss."
      active="Kursübersicht"
    >
      <PageHeader title="Online	Kursangebot" breadcrumb={[{ title: 'Kurse' }]} image={data.imageKurse} classNameImage="max-w-md animate-shake-vertical" wrapperClass="max-w-5xl mx-auto" divider>
        <H2 className="mb-5">Stressfrei und entspannt durch deinen Tag</H2>
        <P className="mb-5">Mit den Kursen der Entspannungshelden entspannst du wann, wo und so oft wie du willst für 365 Tage im Jahr.</P>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
          <div>
            <Button text="Kursangebot" anchor="/kurse/#kurse" className="w-full" type="primary" />
            <div className="mt-2 text-center text-xs text-gray-500">Deine Kurswahl</div>
          </div>
          <div>
            <Button text="Kursquiz" to="/kurse/kursquiz/" className="w-full" type="primary" />
            <div className="mt-2 text-center text-xs text-gray-500">Finde deinen Kurs</div>
          </div>
          <div>
            <Button text="Kassencheck" to="/kurse/krankenkasse/" className="w-full" type="primary" />
            <div className="mt-2 text-center text-xs text-gray-500">Soviel zahlt die Kasse</div>
          </div>
        </div>
      </PageHeader>

      <Container>
        <dl className="mb-16">
          <GridFour itemsStart>
            <Blurb name="Zertifiziert" description="Krankenkassen bezuschussen die Kurse mit 75,00 - 149,00 €" icon={svgs.certificate} />
            <Blurb name="Umsetzbar" description="Mit Downloads und Handouts für die bessere Integration in deinen Alltag" icon={svgs.knowledge} />
            <Blurb name="Intuitiv" description="Einwandfreie Nutzung über deinen Computer, Tablet und Smartphone" icon={svgs.mobile} />
            <Blurb name="Unabhängig" description="365 Tage lang Zugriff auf alle Inhalte, orts- und zeitunabhängige Einteilung" icon={svgs.date} />
          </GridFour>
        </dl>
      </Container>

      <div className={`overflow-hidden bg-brand-green/50`}>
        <Divider className="text-gray-50" />
        <Container id="kurse" className="space-y-16">
          <H2 className="text-center font-display text-brand-gray">Wo stehst du?</H2>
          <GridTwo itemsCenter>
            <div className="order-2 text-brand-gray md:order-1">
              <H3 className="mb-5">Carlotta, 27 Medizinstudentin</H3>
              <P className="mb-3">
                Schläft schlecht, weil noch soviel zu tun ist. Kopfschmerzen und endlose Grübeleien sind ihre stetigen Begleiter. Auch ihr Blutdruck ist leicht erhöht und macht ihr Kummer. Sie hat es schon mit Sport versucht, aber irgendwie
                war das nicht ihrs.{' '}
              </P>
              <P className="mb-5">
                Eine Kommilitonin hat ihr das Autogene Training der Entspannungshelden empfohlen, mit dem Carlotta Orts- und Zeitunabhängig meditieren kann. Nach einem ersten Telefonat mit Kursleiter Johannes ist sie überzeugt und startet
                den 8 wöchigen Kurs.{' '}
              </P>
              <Button to="/kurse/autogenes-training-onlinekurs/" text="Zum Kurs: Autogenes Training" type="primary" />
            </div>
            <div className="order-1 md:order-2">
              <GatsbyImage image={getImage(data.carlotta)} alt="" />
            </div>
          </GridTwo>
          <GridTwo itemsCenter>
            <div>
              <GatsbyImage image={getImage(data.simon)} alt="" />
            </div>
            <div className="text-brand-gray">
              <H3 className="mb-5">Simon, 35 Bürokaufmann </H3>
              <P className="mb-3">Leidet unter Verspannungen der Hals-Nacken Muskulatur und innerer Anspannung. Auch nachts klagt seine Partnerin über die fiesen Knirschgeräusche. Eine Aufbissschiene hilft Simon leider nicht weiter. </P>
              <P className="mb-5">
                Über eine Suchmaschine wird Simon auf die Progressive Muskelentspannung aufmerksam und stößt auf die Entspannungshelden. 365 Tage Zugriff auf alle Kursinhalte, unterstützende Audiodatein und Arbeitsblätter überzeugen ihn.
                Zudem zahlt seine Krankenkasse sogar 149,00 Euro! Er bucht den Kurs bei den Entspannungshelden und startet noch heute.{' '}
              </P>
              <Button to="/kurse/progressive-muskelentspannung-onlinekurs/" text="Zum Kurs: Progressive Muskelentspannung" type="primary" />
            </div>
          </GridTwo>
          <GridTwo itemsCenter>
            <div className="order-2 text-brand-gray md:order-1">
              <H3 className="mb-5">Julia, 31 Sozialarbeiterin</H3>
              <P className="mb-5">
                Trotz selbstorganisierter Arbeitszeit schafft es Julia nicht gut sich zu organisieren, Prioritäten zu setzen oder Dinge die sie sich vorgenommen hat umzusetzen. Sie fühlt sich oft überfordert, müde, erschöpft und weiß gar
                nicht so genau wieso. Sie erinnert sich an den Entspannungshelden Podcast und stöbert auf der Internetseite der Entspannungshelden. Sie findet den Kurs Stressmanagement, bei dem sie lernt Nein zu sagen, eigene Grenzen und
                Bedürfnisse zu erkennen und zu vertreten und startet noch am selben Tag mit dem 8-wöchigen Kursangebot.{' '}
              </P>
              <Button to="/kurse/stressmanagement-onlinekurs/" text="Zum Kurs: Stressmanagement" type="primary" />
            </div>
            <div className="order-1 md:order-2">
              <GatsbyImage image={getImage(data.julia)} alt="" />
            </div>
          </GridTwo>
          <div>
            <H2 className="text-center font-display text-brand-gray">So geht es für dich weiter!</H2>
          </div>
          <GridThree>
            <BlurbNumber number={1} text="Kursquiz absolvieren, den richtigen Kurs für dich auswählen, über Elopage buchen und direkt starten." />
            <BlurbNumber number={2} text="Kurs starten, Video sehen und Kursquiz am Ende bestehen. Pro Woche gibt es ein neues Video." />
            <BlurbNumber number={3} text="Teilnahmebestätigung ausdrucken und ergänzen, einreichen und Geld zurückerhalten." />
          </GridThree>
          <div className="my-16 text-center">
            <Button text="Welcher Kurs passt zu Dir?" to="/kurse/kursquiz/" type="primary" />
          </div>
        </Container>
      </div>

      <Container noMargin className="my-8" id="kurse">
        <Courses />
      </Container>
    </Layout>
  );
}

export default Kurse;

export const query = graphql`
  {
    imageKurse: file(relativePath: { eq: "course-images/autogenes-training.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    carlotta: file(relativePath: { eq: "pages/kurse/carlotta.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100)
      }
    }
    julia: file(relativePath: { eq: "pages/kurse/julia.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100)
      }
    }
    simon: file(relativePath: { eq: "pages/kurse/simon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100)
      }
    }
  }
`;
